import React from 'react';

// Path to the logo file on your project
import logo from '../assets/slac-logo.svg';

const Logo = () => (
  <img src={logo} alt="Salt Lake Acting Company logo" style={{ width: 180 }} />
);

export default Logo;
